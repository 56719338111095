<template>
  <div>
    <div>
      <h2 class="heading-size-4 mb-6">{{ $t("myPM") }}</h2>
      <div>
        <UserPaymentMethods
          :defaultPM="$store.state.userProfile.default_pm"
          @setPaymentMethod="setPaymentMethod"
        />

        <div class="checkout-calculated-price" v-if="paymentMethods.length > 0">
          <CalculatedPrice :currency="currency" :value="calculatedPrice" />

          <v-btn width="200" large color="success" @click="paymentSubmit">
            {{ $t("pay") }}
          </v-btn>
        </div>
        <div v-if="stripeMessage" class="ma-4 checkout-feedback-message">
          {{ stripeMessage }}
        </div>
        <div class="mt-10" style="opacity: 0.2">
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/helpers/firebase";
import "firebase/firestore";
import CalculatedPrice from "@/components/Molecules/CalculatedPrice";

import UserPaymentMethods from "@/components/Organisms/UserPaymentMethods";

export default {
  name: "Checkout3PaymentDetails",
  components: {
    CalculatedPrice,
    UserPaymentMethods,
  },
  props: {
    id: String,
    frameValue: Number,
    subtotal: Number,
    taxValue: Number,
    totalValue: Number,
    currency: { type: String, default: "jpy" },
    nFrames: Number,
    assignedFrames: Array,
    session: String,
  },
  computed: {
    calculatedPrice() {
      const breakdown = {
        frames: this.nFrames,
      };
      (breakdown["frameValue"] = this.frameValue),
        (breakdown["subtotal"] = this.subtotal);
      breakdown["tax"] = this.taxValue;
      breakdown["total"] = this.totalValue;
      return breakdown;
    },
  },
  data() {
    return {
      stripeMessage: "",
      processingPaymentId: "",
      modalWait: false,
      addCardModalIsOpen: false,
      testModal: true,
      stripeErrorMessage: "",
      paymentLoaded: false,
      stripe: {},
      stripeReady: false,
      elements: "",
      card: "",
      customerData: {},
      showCardForm: true,
      paymentMethods: [],
      paymentMethod: {
        cus_id: "",
        pm_id: "",
        text: "",
      },
      stripErrorMessage: "",
      unsubscribe: "",
      unsubscribePM: "",
    };
  },
  methods: {
    setPaymentMethod(pm) {
      this.paymentMethod = pm;
    },
    addCardButtonClicked() {
      this.addCardModalIsOpen = true;
    },
    async addPaymentMethod() {
      this.showCardForm = true;
    },
    async paymentSubmit() {
      this.$emit("waitingPay", true);
      // const form = new FormData(event.target);
      const amount = Number(this.totalValue);
      const tax = Number(this.taxValue);
      const subtotal = Number(this.subtotal);
      const currency = this.currency;
      let frameIds = [];
      this.assignedFrames.forEach((frame) => {
        frameIds.push(frame.frameId);
      });
      const data = {
        payment_method: this.paymentMethod.pm_id,
        currency,
        subtotal: this.formatAmountForStripe(subtotal, currency),
        tax: this.formatAmountForStripe(tax, currency),
        amount: this.formatAmountForStripe(amount, currency),
        status: "new",
        assignedFrames: frameIds,
        artworkId: this.id,
        timestamp: Date.now(),
        session: this.session,
      };

      let paymentRef = await db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payments")
        .add(data);

      this.processingPaymentId = paymentRef.id;

      // this.waiting = false;
      // this.$emit('pay');
      // this.$store.commit('endCountdown');
    },
    startDataListeners() {
      /**
       * Get all payment methods for the logged in customer
       */
      this.unsubscribe = db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payment_methods")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            // document.querySelector('#add-new-card').open = true;
            this.paymentMethods = [];
            this.showCardForm = true;
            return;
          }
          snapshot.forEach(
            function (doc) {
              this.modalWait = true;
              const paymentMethod = doc.data();
              if (!paymentMethod.customer || !paymentMethod.id) return;

              if (!paymentMethod.card) {
                return;
              }

              const pmExists = this.paymentMethods.find(
                ({ pm_id }) => pm_id === paymentMethod.id
              );

              if (pmExists) {
                return;
              }

              const label = `${paymentMethod.card.brand} •••• ${paymentMethod.card.last4} | Expires ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`;
              this.paymentMethods.push({
                cus_id: paymentMethod.customer,
                pm_id: paymentMethod.id,
                text: label,
              });
              if (this.addCardModalIsOpen) {
                document.querySelector("#modalAddButton").click();
              }
              this.modalWait = false;
            }.bind(this)
          );
        });

      /**
       * Get all payments for the logged in customer
       */
      this.unsubscribePM = db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payments")
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const payment = doc.data();
            if (doc.id !== this.processingPaymentId) return;

            let content = "";
            if (payment.error) {
              content = `Payment error: ${payment.error}`;
              this.$emit("waitingPay", false);
              this.$emit("paymentError", payment.error);
            } else if (
              payment.status === "new" ||
              payment.status === "requires_confirmation"
            ) {
              // content = `Creating Payment for ${this.formatAmount(
              //   payment.amount,
              //   payment.currency
              // )}`;
            } else if (payment.status === "succeeded") {
              this.$emit("waitingPay", false);
              this.$emit("pay", payment);
              // This should be a new payment method
              this.$store.commit("endCountdown");
            } else if (payment.status === "requires_action") {
              // content = `Payment for ${this.formatAmount(
              //   payment.amount,
              //   payment.currency
              // )} ${payment.status}`;
              this.handleCardAction(payment, doc.id);
            } else {
              content = `Payment for ${this.formatAmount(
                payment.amount,
                payment.currency
              )} ${payment.status}`;
            }
            this.stripeMessage = content;
          });
        });
    },
    // Format amount for diplay in the UI
    formatAmount(amount, currency) {
      amount = this.zeroDecimalCurrency(amount, currency)
        ? amount
        : (amount / 100).toFixed(2);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      }).format(amount);
    },

    // Format amount for Stripe
    formatAmountForStripe(amount, currency) {
      return this.zeroDecimalCurrency(amount, currency)
        ? amount
        : Math.round(amount * 100);
    },

    // Check if we have a zero decimal currency
    // https://stripe.com/docs/currencies#zero-decimal
    zeroDecimalCurrency(amount, currency) {
      let numberFormat = new Intl.NumberFormat(["en-US"], {
        style: "currency",
        currency: currency,
        currencyDisplay: "symbol",
      });
      const parts = numberFormat.formatToParts(amount);
      let zeroDecimalCurrency = true;
      for (let part of parts) {
        if (part.type === "decimal") {
          zeroDecimalCurrency = false;
        }
      }
      return zeroDecimalCurrency;
    },

    // Handle card actions like 3D Secure
    async handleCardAction(payment, docId) {
      if (payment.next_action.type === "use_stripe_sdk") {
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(
          payment.client_secret
        );
        if (error) {
          alert(error.message);
          payment = error.payment_intent;
          this.$emit("waitingPay", false);
          this.$emit("paymentError", payment.error);
        } else if (paymentIntent) {
          payment = paymentIntent;
          payment.frames = [];
        }
      } else {
        const { error, paymentIntent } = await this.stripe.handleCardAction(
          payment.client_secret
        );
        if (error) {
          alert(error.message);
          payment = error.payment_intent;
          this.$emit("waitingPay", false);
          this.$emit("paymentError", payment.error);
        } else if (paymentIntent) {
          payment = paymentIntent;
        }
      }

      await db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payments")
        .doc(docId)
        .set(payment, { merge: true });
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      /* global Stripe */
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBKEY);
      this.stripeReady = true;

      if (this.$store.state.user) {
        db.collection("stripe_customers")
          .doc(this.$store.state.user.uid)
          .onSnapshot((snapshot) => {
            if (snapshot.data()) {
              this.startDataListeners();
            } else {
              console.warn(
                `No Stripe customer found in Firestore for user: ${this.$store.state.user.uid}`
              );
            }
          });
      } else {
        // document.getElementById('content').style.display = 'none';
      }
    },
  },
  async mounted() {
    this.configureStripe();
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribePM) this.unsubscribePM();
  },
};
</script>

<style lang="scss" scoped>
.checkout-payment-methods {
  @media only screen and (min-width: 600px) {
    display: flex;
    &--between {
      justify-content: space-between;
    }
    &--center {
      justify-content: center;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    &--onlyadd {
      flex-grow: 1;
      align-items: center;
      @media only screen and (min-width: 600px) {
        align-items: flex-end;
      }
    }
  }
}
.radio-item-card {
  height: 50px;
  width: 100%;
  padding: 16px;
}
.checkout-calculated-price {
  background-color: var(--color-white);
  max-width: 320px;
  margin: 24px auto;
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>
