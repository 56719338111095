<template>
  <div>
    <p class="text-center" v-if="paymentMethods.length === 0">
      {{ $t("noPM") }}
    </p>
    <p v-if="paymentMethods.length > 1">{{ $t("choosePM") }}</p>
    <form id="payment-form" @submit.prevent="paymentSubmit">
      <template v-if="paymentMethods.length">
        <div class="user-new-box-checks">
          <NeoBoxCheck
            v-for="item in paymentMethods"
            :key="item.pm_id"
            :default-label="'Default Payment'"
            @select="setPaymentMethod(item)"
            @deleteAddress="deletePM(item)"
            @defaultAddress="setDefaultPaymentMethod(item)"
            :value="item"
            :selected="selectedPaymentId"
          >
            <div class="font-weight-bold">{{ item.name }}</div>
            <div class="font-weight-light">
              <i>{{ item.brand }}</i>
            </div>
            <div class="font-weight-light">
              <i>{{ item.maskedNumber }}</i>
            </div>
            <div>
              {{ item.expires }}
            </div>
          </NeoBoxCheck>
        </div>
      </template>

      <div
        class="checkout-payment-methods"
        :class="{
          'checkout-payment-methods--between': paymentMethods.length > 0,
          'checkout-payment-methods--center': paymentMethods.length === 0,
        }"
      >
        <div
          class="checkout-payment-methods__buttons"
          :class="{
            'checkout-payment-methods__buttons--onlyadd':
              paymentMethods.length === 0,
          }"
        >
          <add-card-modal
            ref="addCardModalRef"
            :modalWait="modalWait"
            :open="isOpen"
            @addCardButtonClicked="addCardButtonClicked()"
            @closeModal="closeModal"
          />
        </div>
      </div>
      <slot></slot>
      <div v-if="stripeMessage" class="ma-4 checkout-feedback-message">
        {{ stripeMessage }}
      </div>
    </form>
  </div>
</template>

<script>
import { db, analytics } from "@/helpers/firebase";
import "firebase/firestore";
import AddCardModal from "@/components/Organisms/AddCardModal";
import NeoBoxCheck from "@/components/Molecules/NeoBoxCheck";

import UsersDS from "@/services/UsersDS";

export default {
  name: "UserPaymentMethods",
  props: {
    defaultPM: String,
  },
  components: {
    AddCardModal,
    NeoBoxCheck,
  },
  watch: {
    "$store.state.user": function () {
      if (!this.unsubscribe) this.startDataListeners();
    },
  },
  data() {
    return {
      stripe: undefined,
      selectedPaymentId: "",
      processingPaymentId: "",
      currency: "",
      modalWait: false,
      isOpen: false,
      waiting: false,
      testModal: true,
      stripeMessage: "",
      paymentLoaded: false,
      elements: "",
      card: "",
      customerData: {},
      showCardForm: true,
      paymentMethods: [],
      paymentMethod: {
        cus_id: "",
        pm_id: "",
        text: "",
      },
      total: 0,
      stripErrorMessage: "",
      defaultPaymentId: undefined,
      firstLoad: true,
      unsubscribe: undefined,
    };
  },
  methods: {
    closeModal() {
      this.isOpen = false;
    },
    async setDefaultPaymentMethod(paymentMethod) {
      if (this.$store.state.userProfile.default_pm !== paymentMethod.pm_id) {
        this.$store.commit("updateDefaultPM", paymentMethod.pm_id);
        await UsersDS.update(this.$store.state.user.uid, {
          default_pm: paymentMethod.pm_id,
        });
      }

      this.paymentMethods.forEach((pm) => {
        pm.default = pm.pm_id === paymentMethod.pm_id;
      });
      this.setPaymentMethod(paymentMethod);
    },
    setPaymentMethod(pm) {
      this.selectedPaymentId = pm.pm_id;
      this.$emit("setPaymentMethod", pm);
    },
    addCardButtonClicked() {
      this.isOpen = true;
    },
    async addPaymentMethod() {
      this.showCardForm = true;
    },
    async deletePM(paymentMethod) {
      const cardDoc = await db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payment_methods")
        .where("id", "==", paymentMethod.pm_id)
        .get();

      await cardDoc.docs[0].ref.delete();
      this.selectedPaymentId = this.$store.state.userProfile.default_pm;
      if (this.paymentMethods.length > 0) {
        this.paymentMethods.some((pm, index) => {
          if (pm.pm_id === paymentMethod.pm_id) {
            this.paymentMethods.splice(index, 1);
          }
        });
      }
      if (this.$store.state.userProfile.default_pm === paymentMethod.pm_id) {
        // Default PM was deleted, set the first one as default
        if (this.paymentMethods.length > 0)
          this.setDefaultPaymentMethod(this.paymentMethods[0]);
      }
    },
    startDataListeners() {
      /**
       * Get all payment methods for the logged in customer
       */
      this.unsubscribe = db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payment_methods")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            this.paymentMethods = [];
            this.showCardForm = true;
            return;
          }
          // List of payment methods
          let numberOfPMS = this.paymentMethods.length;
          let pms = [];
          snapshot.forEach(
            function (doc) {
              const paymentMethod = doc.data();

              if (
                !paymentMethod.customer ||
                !paymentMethod.id ||
                !paymentMethod.card
              )
                return;

              pms.push(paymentMethod.id);
              const pmExists = this.paymentMethods.find(
                ({ pm_id }) => pm_id === paymentMethod.id
              );
              if (pmExists) return;

              const label = `${paymentMethod.card.brand} •••• ${paymentMethod.card.last4} | Expires ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`;

              this.paymentMethods.push({
                cus_id: paymentMethod.customer,
                pm_id: paymentMethod.id,
                default:
                  this.$store.state.userProfile.default_pm === paymentMethod.id,
                text: label,
                brand: paymentMethod.card.brand,
                maskedNumber: `•••• ${paymentMethod.card.last4}`,
                expires: `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`,
              });

              if (!this.firstLoad) {
                // This should be a new payment method
                analytics.logEvent("add_payment_info", {
                  payment_type: paymentMethod.card.brand,
                });
              }
            }.bind(this)
          );
          // Remove any payment methods that are not in the list anymore
          // Check if default pm was deleted
          this.paymentMethods = this.paymentMethods.filter((pm) => {
            {
              return pms.indexOf(pm.pm_id) > -1;
            }
          });

          // Here there should be a payment method available - send one back to the parent
          let chosenPM = undefined;
          if (this.paymentMethods.length > 0) {
            if (pms.indexOf(this.$store.state.userProfile.default_pm) === -1) {
              // Default payment ID is not there anymore
              this.setDefaultPaymentMethod(this.paymentMethods[0]);
              this.defaultPaymentId = this.paymentMethods[0].pm_id;
              chosenPM = this.paymentMethods[0];
            } else {
              chosenPM = this.paymentMethods.filter((pm) => {
                {
                  return pm.pm_id === this.$store.state.userProfile.default_pm;
                }
              })[0];
            }
            this.setDefaultPaymentMethod(chosenPM);
          } else {
            this.$emit("setPaymentMethod", undefined);
          }
          if (!this.firstLoad && this.paymentMethods.length > numberOfPMS)
            this.closeModal();
          this.firstLoad = false;
        });
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  mounted() {
    if (this.$store.state.user) {
      this.startDataListeners();
    }
  },
};
</script>

<style lang="scss" scoped>
.user-new-box-checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}
.checkout-payment-methods {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
  &__buttons {
    max-width: 240px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--onlyadd {
      flex-grow: 1;
      align-items: center;
      @media only screen and (min-width: 600px) {
        align-items: flex-end;
      }
    }
  }
}
.radio-item-card {
  height: 50px;
  width: 100%;
  padding: 16px 8px;
}
.checkout-calculated-price {
  background-color: var(--color-white);
  max-width: 320px;
  margin: 24px auto;
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>
