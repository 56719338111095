<template>
  <div class="calculated-price">
    <template v-for="(item, i) in value">
      <div class="calculated-price__item" :key="i">
        <div>{{ $t(i) }}:</div>
        <div>
          <span v-if="i !== 'frames'">{{ formatAmount(currency, item) }}</span
          >
          <span v-else>{{ item }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers.js';

export default {
  name: 'CalculatedPrice',
  mixins: [helpers],
  props: {
    value: Object,
    currency: { type: String, default: 'jpy' },
  },
};
</script>

<style lang="scss" scoped>
.calculated-price {
  border: 1px solid var(--color-white);
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: var(--font-size-extra-small);
    &:last-child {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 2px solid var(--color-black);
    }
    > :last-child {
      font-size: var(--font-size-4);
      font-weight: 600;
      color: var(--color-black);
      text-align: right;
    }
  }
}
</style>
