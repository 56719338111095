<template>
  <NeoModal
    v-model="dialog"
    :max-width="450"
    :title-header="$t('addPM')"
    @close="closeModal"
    eager
  >
    <template v-slot:activator="{ on }">
      <v-btn
        id="modalAddButton"
        color="primary"
        v-on="on"
        @click="addCardButtonClicked"
      >
        {{ $t("addPM") }}
      </v-btn>
    </template>

    <v-card-text>
      <div class="loader" v-if="loading">
        <div class="waiting">
          <img src="@/assets/neo-spinner.svg" alt="" />
        </div>
        <div class="text">{{ text }}</div>
      </div>
      <form
        ref="pmForm"
        id="payment-method-form"
        @submit.prevent="submitForm"
        class="pb-6 text-center"
      >
        <div id="payment-element"></div>

        <div v-if="stripeErrorMessage" class="checkout-feedback-message mt-4">
          {{ stripeErrorMessage }}
        </div>

        <v-btn
          class="mt-6"
          :disabled="waiting || modalWait || disabledAddButton"
          color="primary"
          type="submit"
          id="submit"
        >
          {{ $t("addCard") }}
          <v-icon size="32" v-if="waiting || modalWait" class="ml-1 waiting">
            mdi-tire
          </v-icon>
        </v-btn>
      </form>
    </v-card-text>
  </NeoModal>
</template>

<script>
import { db, auth } from "@/helpers/firebase";
import "firebase/firestore";
import NeoModal from "@/components/Molecules/NeoModal";

let elements = null;

export default {
  name: "AddCardModal",
  components: {
    NeoModal,
  },
  props: {
    modalWait: Boolean,
    open: Boolean,
    // stripe: Object,
  },
  data() {
    return {
      text: "Loading...",
      loading: true,
      dialog: false,
      disabledAddButton: true,
      paymentElement: null,
      paymentLoaded: false,
      // stripe: '',
      elements: "",
      card: "",
      customerData: {},
      showCardForm: true,
      paymentMethods: [],
      paymentMethod: {
        cus_id: "",
        pm_id: "",
        text: "",
      },
      stripeErrorMessage: "",
      waiting: false,
      stripeSecret: undefined,
    };
  },

  methods: {
    addCardButtonClicked() {
      this.waiting = false;
      this.$emit("addCardButtonClicked");
      this.loading = true;
    },
    closeModal() {
      this.dialog = false;
      this.stripeErrorMessage = '';
      this.$emit("closeModal");
      if (this.paymentElement) {
        this.paymentElement.removeEventListener("change");
        this.paymentElement.unmount("#payment-element");
        this.paymentElement.destroy();
        this.paymentElement = null;
      }

      if (elements) {
        elements = null;
      }

      this.stripe = null;
      // this.clearCardForm();
    },
    async submitForm() {
      this.disabledAddButton = true;
      this.paymentElement.update({ readOnly: true });

      this.waiting = true;
      const { setupIntent, error } = await this.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
        // confirmParams: {
        //   return_url: 'http://localhost:8080/',
        // },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (e.g., payment
        // details incomplete)
        this.stripeErrorMessage = error.message;
        this.waiting = false;
        this.paymentElement.update({ readOnly: false });
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        this.stripeErrorMessage = "";
        await db
          .collection("stripe_customers")
          .doc(this.$store.state.user.uid)
          .collection("payment_methods")
          .add({ id: setupIntent.payment_method });
        // this.$emit('closeModal')
      }
    },

    async configureStripe() {
      if (auth.currentUser) {
        const stripeSnapshot = await db
          .collection("stripe_customers")
          .doc(auth.currentUser.uid)
          .get();
        if (stripeSnapshot) {
          this.stripeSecret = stripeSnapshot.data().setup_secret;
        }

        if (this.stripeSecret) {
          /* global Stripe */
          this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBKEY);
          // this.stripeReady = true;

          const options = {
            clientSecret: this.stripeSecret,
            locale: this.$store.state.lang,
            // Fully customizable with appearance API.
            appearance: {
              theme: "stripe",
            },
          };

          // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2

          elements = this.stripe.elements(options);

          // Create and mount the Payment Element
          this.showCardForm = true;
          // if (!this.paymentLoaded) {
          this.paymentElement = elements.create("payment");
          this.paymentElement.mount("#payment-element");
          // this.paymentLoaded = true;
          this.paymentElement.addEventListener("change", (event) => {
            if (event.complete) {
              this.disabledAddButton = false;
            }
            if (event.empty) this.loading = false;
            return;
          });
        } else {
          console.warn(
            `No Stripe customer found in Firestore for user: ${this.$store.state.user.uid}`
          );
        }
      }
    },
  },
  unmounted() {
    console.log("unmounted");
  },
  mounted() {
    this.loading = true;
  },
  watch: {
    "$store.state.lang": function () {
      if (elements) elements.update({ locale: this.$store.state.lang });
    },
    open: async function (newV) {
      if (!newV) this.closeModal();
      else this.configureStripe();
    },
  },
};
</script>

<style lang="scss" scoped>
// .center {
//   display: flex;
//   align-content: center;
//   justify-content: center;
// }

.loader {
  min-height: 150px;
  div {
    margin: auto;
    text-align: center;
  }
  img {
    margin: auto;
  }
  .text {
    color: var(--color-primary);
    font-weight: bold;
  }
}
</style>
